// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import { UserLoginModel, UserLoggedInfoModel } from "@/models/userModels";
import { mapActions } from "vuex";
import axios, { AxiosPromise } from "axios";
import baseContextComponent from "@/components/baseContextComponent";
import api from "@/services/api";

@Component({
	methods: { ...mapActions(["loginUser"]) }
})
export default class loginPage extends baseContextComponent {
	showPwd: boolean = false;
	isLoading: boolean = false;
	automaticLogin: boolean = false;
	user: UserLoginModel = new UserLoginModel();
	loginUser!: (token: any) => Promise<UserLoggedInfoModel>;
	emailRules = [
		(v: string) => {
			return !!v || "Campo obbligatorio";
		},
		(v: string | null) => v == null || /.+@.+/.test(v) || "Formato non valido"
	];
	passwordRules = [(v: string | null) => !!v || "Campo obbligatorio"];

	created() {
		var self = this;
		self.automaticLogin = true;
		if (self.$route.query.tk) {
			var instance = this;

			var token = self.$route.query.tk;

			let path = instance.$route.query.returnUrl || "/";

			if (path == "/") {
				path = "/gestione-vendite/ricerca-garanzie";
			}

			instance.loginUser(token).then(() => {
				instance.$router.push({ path: path as string });
			}).catch(err => self.automaticLogin = false);
		}
		else
			self.automaticLogin = false;
	}

	checkForm(e: any) {
		var instance = this;
		e.preventDefault();
		instance.isLoading = true;
		api.doJwtLogin(instance.user)
			.then(token => {
				let path = instance.$route.query.returnUrl || "/";

				if (path == "/") {
					path = "/gestione-vendite/ricerca-garanzie";
				}

				instance.loginUser(token).then(() => {
					instance.$router.push({ path: path as string });
				});
				instance.isLoading = false;
			})
			.catch(error => {
				console.error(error);
				instance.isLoading = false;
			});
	}
	get headerText(): string {
		return this.$appConfig.loginPage.headerText;
	}
	get htmlText(): string {
		return this.$appConfig.loginPage.htmlText;
	}
}
